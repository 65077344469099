
.Innerdiv{
    min-height: 100vh;
    background: url("/public/Images/backimg.png");
    background-size: cover;
    background-position: center;
}
.Navouter{
    min-height: 100vh;
    background: url("/public/Images/sidenave.png");
    background-size: cover;
    background-position: bottom;
    position: fixed;
    width: 250px;
    min-height: 100%;
}
.ContWidth{
    max-width: 100% !important;
}
.imgsetts img {
    width: 55px;
}
.P25{
    padding: 25px;
}
.P0{
    padding: 0px;
}
.cardtext {
    font-size: 14px;
    font-weight: 500;
}
.M0{
    margin: 0px;
}
.menubtn {
    background: none !important;
    border: none !important;
}
.algndiv {
    float: right;
}
.algndiv img {
    width: 40px;
    margin-bottom: 12px;
}
.Mainheading {
    font-family: 'Cooper Black', serif;
    color: #ddd;
    font-size: 60px;
    text-transform: uppercase;
}
.tablecls {
    width: 100%;
}
.tablecls th {
    padding: 10px 10px;
    font-weight: 500;
    font-size: 14px;
}
.tablecls td {
    padding: 26px 10px 10px 10px;
    text-transform: capitalize;
}
.trcls {
    background: #f5f7fb;
}
.MT20{
    margin-top: 20px;
}
.DisplaDiv {
    padding-left: 32px;
    padding-top: 15px;
}
.MT50{
    margin-top: 50px;
}
.searchbar {
    width: 82% ! IMPORTANT;
    margin-top: 30px;
}
.searchbar1 {
    width: 82% ! IMPORTANT;
  
}
.Searchinput {
    height: 50px;
    border-radius: 13px !important;
    &::placeholder {
    font-size: 15px;
    position: relative;
    top: -2px;
      }
}

.searchbtn {
    width: 50px;
    background: #ff3535 !important;
    border: none !important;
    right: 57px;
    border-radius: 11px !important;
    margin: 5px;
    z-index: 10 !important;
}
.welcomcls {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 6px;
}
.userNcls {
    font-size: 35px;
    font-weight: 800;
    text-transform: capitalize;
}
.reasultcard {
    margin-top: 20px;
    margin-left: 30px !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.descset {
    color: #fff;
    font-size: 17px;
    margin-bottom: 6px;
}
.spancls {
    font-weight: 300;
}
.spancls b {
    font-weight: 700;
}
.MR10 {
    margin-right: 10px;
}
.ML10 {
    margin-left: 10px;
}
.renewcls {
    margin-left: 6px;
    font-weight: 500;
    color: #ff5f5f;
}
.stratbtn {
    text-transform: uppercase;
    padding: 12px 46px;
    background: #66a930;
    border: none;
    font-size: 18px;
    color: #fff;
    font-weight: 800;
    margin-top: 20px;
    border-radius: 8px;
}
.Endbtn {
    text-transform: uppercase;
    padding: 12px 46px;
    background: #b13333;
    border: none;
    font-size: 18px;
    color: #fff;
    font-weight: 800;
    margin-top: 20px;
    border-radius: 8px;
}
.algndiv2 {
    text-align: center;
}
.numnercls {
    font-size: 25px;
    font-weight: 700;
}
.algndiv {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}
.Cardinner {
    background: #fff;
    box-shadow: 0px 1px 7px 4px #e7e7e7;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    padding: 12px 16px 12px 5px;
    min-height: 136px;
    
}
.Usercls {
    font-size: 18px;
}
.P22{
    padding: 22px 48px;
}
.gotoclss {
    color: #fff;
    font-size: 75px;
    font-weight: 800;
    text-align: center;
}
.logoutcls {
    text-align: right;
}
.BW {
    background: #fff;
    box-shadow: 1px 2px 5px #ececec;
    padding: 22px 32px;
}
.Mainouter {
    margin-left: 250px;
    background: #f4f5f6;
    min-height: 100vh;

}
.logodiv {
    text-align: center;
    padding-top: 30px;
}
.logodiv img {
    width: 80px;
}
.Atag img {
    width: 26px;
    margin-right: 7px;
}
.Atag {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    padding: 14px 22px;
    display: block;
}
.navul {
    padding: 0px;
    list-style: none;
    margin-top: 40px;
}
.Atag.active {
    background: #ffffff3b;
    color: #ffdf00;
}
.Atag:hover {
    background: #ffffff3b;
}
.logoutcls img {
    width: 16px;
    margin-right: 8px;
}
.W100{
    max-width: 100% !important;
}
.addbtn {
    background: none;
    border: none;
}
.T20 {
    margin-top: 25px;
}
.Searchinput1{
    height: 50px;
    border-radius: 7px !important;
}
.tableclsdiv {
    background: #fff;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    padding: 20px 20px;
    overflow: auto;
}
.dayleftcls {
    color: #ffb524;
    font-weight: 600;
    font-size: 14px;
}
.expiredago {
    color: #ff0000;
    font-weight: 600;
    font-size: 14px;
}
.Rdate {
    margin: 2px;
}
.Rbtn {
    background: #e5f8ed;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #077134;
}
.iconbtns img {
    width: 24px;
}
.iconbtns {
    background: none;
    border: none;
}
@media only screen and (max-width: 1024px) {
    .Mainouter {
        margin-left: 0px;
    }
    .Cardinner {

        margin-bottom: 25px;
    }
    .AddModalcls .modal-dialog {
        max-width: 100% !important;
    }
    .P22 {
        padding: 22px 25px;
    }
    .searchbar1 {
        width: 100% ! IMPORTANT;
    }
    .RenewModals .modal-dialog {
        max-width: 100% !important;
    }
    .BW {
        padding: 15px 8px;
    }
    .homebtnstyle {

        margin-right: 7px;
        margin-top: 2px;
    }
    .innerdivlog {
        width: 98% !important;
        margin: 0 !important;
    }
    .loginpagedesign {
        padding: 0px 22px 0px 26px !important;
    }

  }

  .AddModalcls .modal-dialog {
    max-width: 70%;
}
.AddModalcls .modal-header {
    background: #218e62;
}
.AddModalcls {
    background: #0000009e;
}
.Mtitle {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
}

.AddModalcls button.btn-close {
    font-size: 11px;
    margin-right: 12px;
    border: 1px solid #fff;
    box-shadow: 1px 2px #c5c5c5;
}
.AddModalcl .modal-body {
    padding: 20px 30px;
}
.Addmemberform input:focus, .Addmemberform select:focus {
    box-shadow: none;
}
.Addmemberform input, select {
    height: 45px !important;
    border-radius: 4px;
    border: 1px solid #dadada;
}
.MB28 {
    margin-bottom: 28px;
}
.AddModalcls .Mbody.modal-body {
    padding: 35px 55px;
}
.AddModalcls .modal-footer {
    text-align: center;
    display: block;
    border: none;
    margin-bottom: 20px;
}
.submitbtn {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    background: #218e62;
    border: none;
    padding: 8px 20px;
}
.closebtn {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    background: #c8c8c8;
    border: none;
    padding: 8px 30px;
    color: #333333;
}
.submitbtn:hover {
    background: #13704b;
}
.closebtn:hover {
    background: #686868;
}
.Searchinput1:focus {
    box-shadow: none;
}
.Rbtn:hover {
    background: #12994a;
    color: #fff;
    transition: 0.5s;
}
.historyCalender:hover {
    transform: scale(1.5);
    scale: 0.8;
    transition: 0.8s;
}
.submitbtn1 {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    background: #326ecf;
    border: none;
    padding: 8px 20px;
}
.form-control:disabled {
    background-color: #ececec;
    opacity: 1;
    pointer-events: none;
}
.historydiv {
    display: flex;
    align-items: end;
}
.RenewModals .modal-header {
    background: #326ecf;
}
.historyCalender {
    background: no-repeat;
    border: none;
}
.historycls {
    display: inline-block;
    font-weight: 500;
    color: #d29b00;
    margin-right: 15px;
}


.historyCalender img {
    width: 30px;
}
.RenewModals .Mbody.modal-body {
    padding: 35px 55px;
}
.RenewModals .modal-footer {
    text-align: center;
    display: block;
  
    margin-bottom: 20px;
}
.RenewModals .modal-dialog {
    max-width: 70%;
}

.RenewModals {
    background: #0000009e;
}

.RenewModals button.btn-close {
    font-size: 11px;
    margin-right: 12px;
    border: 1px solid #fff;
    box-shadow: 1px 2px #c5c5c5;
}
.Cambtn {
    padding: 3px 15px;
    background: #ffffff;
    height: 42px;
}
.filters:focus {
    box-shadow: none !important;
}
.Cambtn img {
    width: 25px;
}
.spancls1 {
    font-weight: 300;
    margin-left: 10px;
}
p.error-message {
    font-size: 18px;
    color: white;
    margin-top: 18px;
    padding: 25px 20px;
}
.Cambtn:hover {
    background: #e0e0e0;
}
.removeBtn {
    background: #ff2929;
    border: none;
    margin-left: 18px;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 18px;
    color: #fff;
}
.caturebtn {
    background: #3a69c2;
    border: none;
    margin-left: 30px;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 18px;
    color: #fff;
    text-transform: uppercase;
    top: -12px;
    position: relative;
}
.MuiAlert-icon {
    color: #ffffff !important;
}
.alert1 {
    background: #00ac05 !important;
    color: #fff !important;
}
button.submitbtn:disabled {
    background: #939393;
    cursor: not-allowed;
}
.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    z-index: 999;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .swal2-container.swal2-center.swal2-backdrop-show {
    background: #000000c7;
}
.calenderspan {
    position: relative;
    top: -12px;
    left: -12px;
    background: #32c000 !important;
}

.calenderstyle {
    width: 100% !important;
    border: none !important;
}
.calenderheding {
    font-size: 15px;
    color: #064dd3;
}
.react-calendar__tile--now {
    background: #e6e6e6 !important;
}
.calenderstyle .react-calendar__month-view__days__day {
    padding: 15px 17px 8px 24px !important;
}
abbr {
    text-decoration: none !important;
}
.profilepic{
    width: auto;
    max-width: 200px;
}
.removeimgsyle {
    background: none;
    border: none;
    display: inline-block;
    padding: 0px;
    position: relative;
    margin-left: 40px;
}
.removeimgsyle img {
    width: 30px;
}
.divimgcls {
    display: inline-block;
}
.removeimgsyle:hover {
    background: none;
}
.homeimg {
    width: 130px;
    border-radius: 100px;
    border: 3px solid #fff;
    object-fit: cover;
    height: 130px;
    object-position: top;
}
.Mt20{
    margin-top: 20px;
}
.defaultcls{
    width: 130px;
    border-radius: 100px;

    object-fit: cover;
    height: 130px;
    object-position: top;
}
.expiredago1 {
    font-size: 30px !important;
    margin-left: 10px;
    font-weight: 900 !important;

}
.absentcls {
    font-size: 14px;
    font-weight: 500;
    color: #ff4c4c;
    margin-left: 6px;
}
.textalgncenter{
    text-align: center;
}
.amounttd {
    font-size: 28px;
    font-weight: 700;
}
.homebtnstyle a {
    text-decoration: none;
    font-weight: 600;
    background: #3e649f;
    padding: 5px 20px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
}
.homebtnstyle {
    text-align: right;
    margin-right: 7px;
}

.loginpagedesign {
    min-height: 100vh;
    
    background: linear-gradient(0deg, rgb(0 0 0 / 60%), rgb(0 0 0 / 60%)), url(/public/Images/logback.jpg);
    background-size: cover;
    background-position: center;
}
.innerdivlog {
    text-align: center;
    width: 500px;
    margin: 0 auto;
    background: #fff;
    min-height: 350px;
    top: 80px;
    position: relative;
    border-radius: 15px;
    padding: 30px 10px;
}
.innerdivlog h2 {
    font-size: 38px;
    padding: 25px 10px;
    color: #646464;
    font-weight: 600;
    text-transform: uppercase;
}
.loginpagedesign input:focus-visible {
    outline: none;
}
.loginpagedesign input {
    width: 70%;
    min-height: 45px;
    border-radius: 6px;
    background: none;
    border: 1px solid #d2d2d2;
    padding: 8px 11px;
}
.loginpagedesign button {
    margin-top: 25px;
    border: none;
    background: #065ad7;
    padding: 6px 100px 9px 100px;
    border-radius: 8px;
    color: #ffffff;
}
.UsernameImg img {
    width: 45px;
    border-radius: 100px;
    border: 3px solid #fff;
    object-fit: cover;
    height: 45px;
    object-position: top;
    cursor: pointer;
}
.UsernameImg img:hover {
    transform: scale(4.5);
    transition: 0.5s;
}
.expiredago1 {
    font-size: 24px; /* Adjust text size as needed */
    color: red; /* Text color */
    animation: blink 1s infinite; /* Animation name, duration, and repeat */
  }
  
  @keyframes blink {
    0% { opacity: 1; }  /* Fully visible */
    50% { opacity: 0; } /* Hidden */
    100% { opacity: 1; } /* Fully visible again */
  }